body {
  font-family: 'coranto-2', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fef7ea;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

svg,
img {
  max-width: 100%;
  height: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  svg {
    height: auto;
    height: initial;
  }
}

button {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;

  font-family: 'Roboto', sans-serif;
}

a {
  position: relative;
  color: #001F42;
}
